import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/sign_in'
  },
  {
    path: '/sign_in',
    name: 'sign-in',
    props: true,
    component: () => import('@/views/SignIn')
  },
  {
    path: '/sign_up',
    name: 'sign-up',
    props: true,
    component: () => import('@/views/SignUp')
  },
  {
    path: '/regist/company',
    name: 'regist-company',
    props: true,
    component: () => import('@/views/BaseView')
  },
  {
    path: '/regist/user',
    name: 'regist-user',
    props: true,
    component: () => import('@/views/BaseView')
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders/create',
    name: 'order-create',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders/create/:formatId',
    name: 'order-create-format',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders/batch_create',
    name: 'order-batch-create',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders/batch_create/:formatId',
    name: 'order-batch-create-format',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders/:orderId',
    name: 'order-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/orders/:orderId/:workId',
    name: 'work-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/r/:r',
    name: 'redirect',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/formats',
    name: 'formats',
    component: () => import('@/views/BaseView')
  },
  {
    // /formats/createは最終的にはformats/edit/と同じコンポーネントに行くが、idを受け取らない
    // 互換性維持のために存在し、将来的に削除予定
    path: '/formats/create',
    name: 'format-create',
    component: () => import('@/views/BaseView')
  },
  {
    // /formats/createは最終的にはformats/edit/と同じコンポーネントに行くが、idを受け取らない
    // 互換性維持のために存在し、将来的に削除予定
    path: '/formats/default/:formatId',
    name: 'default-format',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/formats/edit/:formatId',
    name: 'format-edit',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/formats/detail/:formatId',
    name: 'format-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/spowers',
    name: 'spowers',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/spowers/:accessibleUserId',
    name: 'spower-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/spots',
    name: 'spots',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/spots/:spotId',
    name: 'spot-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/invoices/:date',
    name: 'invoice-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/invoices/:date/charged',
    name: 'charge-invoice-detail',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/messages',
    name: 'message-list',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/messages/:messageRoomId',
    name: 'message',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/point/charge',
    name: 'point-charge',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/text_field_ng_lists',
    name: 'text-field-ng-lists',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/text_field_ng_list/create',
    name: 'text-field-ng-list-create',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/text_field_ng_list/edit/:ngListId',
    name: 'text-field-ng-list-edit',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/schedules',
    name: 'schedules',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/schedules/create',
    name: 'schedule-create',
    component: () => import('@/views/BaseView')
  },
  {
    path: '/schedules/:scheduleId',
    name: 'schedule-detail',
    component: () => import('@/views/BaseView')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
